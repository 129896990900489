<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-22 18:50:12.998 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div class="about-us">
        <div class="container">
            <div
                v-if="$i18n.locale === 'es'"
                class="banner banner-es"
            />
            <div
                v-else
                class="banner"
            />
            <div class="text">
                <div class="text-content">
                    <p
                        v-for="(text, index) in $t('aboutUs.text') "
                        :key="index"
                    >
                        {{ text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="babel/text">
    export default {
        name: 'AboutUs',

        title() {
            return this.$t('aboutUs.title');
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~olacred/common/style/olacred";

    .about-us {
        .container {
            padding-top: 120px;

            @media screen and (max-width: $screen-md) {
                padding: 20px;
            }
        }

        .banner {
            width: 100%;
            padding-top: 21%;
            background-image: url("./img/about-pc.png");
            background-size: contain;
            background-repeat: no-repeat;

            @media screen and (max-width: $screen-sm) {
                padding-top: 32%;
                background-image: url("./img/about-mobile.png");
            }

            &-es {
                background-image: url("./img/about-pc-es.png");

                @media screen and (max-width: $screen-sm) {
                    background-image: url("./img/about-mobile-es.png");
                }
            }
        }

        .text {
            &-title {
                margin: 30px 0;
                font-size: 36px;
                line-height: 36px;
                font-weight: 700;
                color: #000;
            }

            &-content {
                padding: 0 0 60px;
                color: #666;
                font-size: 17px;
                line-height: 1.3;

                p {
                    margin-top: 16px;
                }

                @media screen and (max-width: $screen-md) {
                    font-size: 14px;
                }
            }
        }

        &.webview {
            min-height: 100vh;
        }
    }
</style>
