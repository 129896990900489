var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about-us" }, [
    _c("div", { staticClass: "container" }, [
      _vm.$i18n.locale === "es"
        ? _c("div", { staticClass: "banner banner-es" })
        : _c("div", { staticClass: "banner" }),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _c(
          "div",
          { staticClass: "text-content" },
          _vm._l(_vm.$t("aboutUs.text"), function (text, index) {
            return _c("p", { key: index }, [
              _vm._v(
                "\n                    " + _vm._s(text) + "\n                "
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }